import React, {useState} from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { urlDev } from "../utils/API";

const ChangePassword = () => {
    const [formData, setFormData] = useState({
      new_password: "",
      confirm_password: "",
    });

    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData({
        ...formData,
        [name]: value,
      });
    };

    const handleSubmit = async(e) => {
      e.preventDefault();
      if (formData.new_password === formData.confirm_password) {
        // Passwords match, handle submission here
        const {username} = JSON.parse(localStorage.getItem("admin"));
        
        await axios.put(`${urlDev}/api/admin/changeCredentials`, {username, password: formData.new_password})
        .then(res =>{
            Success.fire({
                icon: "success",
                title: res.data.message,
            })

        })
      } else {
         Error.fire({
           icon: "error",
           title: "Passwords do not match!",
         });
      }
    };
  return (
    <div className="content-wrapper">
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-12">
              <h4 className="font-weight-bold">Change Password</h4>
            </div>
          </div>
        </div>
      </section>

      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-8">
              <div className="card">
                <div className="card-body">
                  <form onSubmit={handleSubmit}>
                    <div className="form-group">
                      <label>New Password</label>
                      <input
                        type="password"
                        className="form-control"
                        placeholder="New Password"
                        name="new_password"
                        onChange={handleChange}
                      />
                    </div>
                    <div className="form-group">
                      <label>Confirm Password</label>
                      <input
                        type="password"
                        className="form-control"
                        placeholder="Confirm Password"
                        name="confirm_password"
                        onChange={handleChange}
                      />
                    </div>
                    <div className="form-group">
                      <button className="btn btn-info" type="submit">
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ChangePassword;

const Error = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 5000,
  // timerProgressBar: true,
  didOpen: (toast) => {
    toast.onmouseenter = Swal.stopTimer;
    toast.onmouseleave = Swal.resumeTimer;
  },
});

const Success = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
  // timerProgressBar: true,
  didOpen: (toast) => {
    toast.onmouseenter = Swal.stopTimer;
    toast.onmouseleave = Swal.resumeTimer;
  },
});